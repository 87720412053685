@import 'assets/styles/_mixins.scss';

.section {
  :global(.swiper-pagination) {
    display: flex !important;
    width: max-content !important;
    position: absolute !important;
    left: unset !important;
    right: 16px !important;
    bottom: 33px !important;

    @include maxWidth($mobile-xl) {
      bottom: 30px !important;
      right: unset !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  :global(.swiper-wrapper) {
    align-items: center !important;
  }

  :global(.custom-pagination-bullet) {
    width: 28px;
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #ffffff1a;
    border-radius: 50%;
    background-color: transparent !important;
    color: #ffffffcc;
    opacity: 1;

    @include maxWidth($mobile-xl) {
      width: 38px;
      height: 38px;
    }
  }

  :global(.swiper-pagination-bullet-active) {
    border-color: #ffffff80 !important;
    color: $white !important;
  }

  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    backdrop-filter: blur(20px);
  }

  &_background {
    & img {
      object-fit: cover;
    }
  }
}

.slider {
  width: 100%;
  max-width: 1368px;

  @include maxWidth($mobile-xl) {
    overflow: visible;
  }

  @include maxWidth($mobile-xl) {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      pointer-events: none;
      z-index: 10;
    }
  }

  &__image_container {
    aspect-ratio: 1 / 0.486;
  }

  &__video {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 0.486;
  }

  &__slide {
    position: relative;
    width: 100%;
    display: flex;

    @include maxWidth($mobile-xl) {
      aspect-ratio: 0.763 / 1;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;

      @include maxWidth($mobile-xl) {
        display: none;
      }
    }

    &_button {
      width: 251px;
      height: 68px;
      padding: 0;
      & p {
        font-size: 35px;
      }
    }

    &__text_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 11;
      text-align: center;

      @include maxWidth($mobile-xl) {
        bottom: 30%;
      }

      &__text_block {
        margin-bottom: 20px;
        @include maxWidth($mobile-xl) {
          display: none;
        }

        &--mobile {
          display: none;
          text-align: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;
          background: $black;
          z-index: 2;
          position: relative;

          @include maxWidth($mobile-xl) {
            display: flex;
          }
        }
      }

      &__title {
        @include maxWidth($mobile-xl) {
          @include h2;
        }
      }

      &__category {
        margin-top: 6px;
        opacity: 70%;
      }

      & button {
        @include maxWidth($mobile-xl) {
          font-size: 21px;
        }
      }

      &__enter {
        transform: translateY(-150px);
      }

      &__enterActive {
        transform: translateY(0);
        transition: transform 0.3s linear;
      }

      &__exit {
        transform: translateY(0);
      }

      &__exitActive {
        transform: translateY(-150px);
        transition: transform 0.3s linear;
      }
    }

    & img {
      object-fit: cover;
    }
  }
}

.bottom_filler {
  display: none;

  @include maxWidth($mobile-xl) {
    display: block;
    height: 70px;
    background-color: $black;
  }
}
